  new Swiper('#js-aboutVoiceCarouselSwiper', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      375: {
        slidesPerView: 'auto',
      },
    },
    centeredSlides: true,
    loop: true,
    spaceBetween: 16,
    pagination: {
      clickable: true,
      el: '.js-aboutVoiceCarouselSwiperPagination',
    },
    slidesPerView: 1.2,
  });

  $('.js-accordion-item-button').on('click', (event) => {
    $(event.currentTarget).toggleClass('-active');
    $(event.currentTarget).next('.js-accordion-item-body').slideToggle();
    return false;
  });

  $('.js-business-result-button').on('click', (event) => {
    $(event.currentTarget).toggleClass('-active');
    $('.js-business-result-hidden').slideToggle();
    return false;
  });

  $('.js-q').on('click', (event) => {
    $(event.currentTarget).toggleClass('-active');
    $(event.currentTarget).next('.js-a').slideToggle();
    return false;
  });

  new Swiper('#js-freelanceColumnCarouselSwiper01', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        spaceBetween: 16,
        slidesPerView: 1.2,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination-xxx',
          clickable: true,
        },
      },
      768: {
        spaceBetween: 31,
        slidesPerView: 3,
      },
    },
  });

  new Swiper('#js-freelanceColumnCarouselSwiper02', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        spaceBetween: 16,
        slidesPerView: 1.2,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination-yyy',
          clickable: true,
        },
      },
      768: {
        spaceBetween: 31,
        slidesPerView: 3,
      },
    },
  });

  new Swiper('#js-freelanceColumnCarouselSwiper03', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        spaceBetween: 16,
        slidesPerView: 1.2,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination-zzz',
          clickable: true,
        },
      },
      768: {
        spaceBetween: 31,
        slidesPerView: 3,
      },
    },
  });

  $('#js-hamburger').on('click', (event) => {
    $('#js-sp-nav').slideDown();
    return false;
  });

  new Swiper('#js-homeProjectCarouselSwiper', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 12,
      },
    },
    loop: true,
    spaceBetween: 8,
    navigation: {
      nextEl: '.js-homeProjectCarouselSwiperNext',
      prevEl: '.js-homeProjectCarouselSwiperPrev',
    },
    pagination: {
      clickable: true,
      el: '.js-homeProjectCarouselSwiperPagination',
    },
    slidesPerView: 1.5,
  });

  const homeProjectCarouselSwiper2_tab1Options = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 12,
      },
    },
    loop: true,
    spaceBetween: 8,
    navigation: {
      nextEl: '.js-homeProjectCarouselSwiper2Next_tab1',
      prevEl: '.js-homeProjectCarouselSwiper2Prev_tab1',
    },
    pagination: {
      clickable: true,
      el: '.js-homeProjectCarouselSwiper2Pagination_tab1',
    },
    slidesPerView: 1.5,
  };

  let homeProjectCarouselSwiper2_tab1 = new Swiper('#js-homeProjectCarouselSwiper2_tab1', homeProjectCarouselSwiper2_tab1Options);

  const homeProjectCarouselSwiper2_tab2Options = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 12,
      },
    },
    loop: true,
    spaceBetween: 8,
    navigation: {
      nextEl: '.js-homeProjectCarouselSwiper2Next_tab2',
      prevEl: '.js-homeProjectCarouselSwiper2Prev_tab2',
    },
    pagination: {
      clickable: true,
      el: '.js-homeProjectCarouselSwiper2Pagination_tab2',
    },
    slidesPerView: 1.5,
  };

  let homeProjectCarouselSwiper2_tab2 = new Swiper('#js-homeProjectCarouselSwiper2_tab2', homeProjectCarouselSwiper2_tab2Options);

  const homeProjectCarouselSwiper2_tab3Options = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 12,
      },
    },
    loop: true,
    spaceBetween: 8,
    navigation: {
      nextEl: '.js-homeProjectCarouselSwiper2Next_tab3',
      prevEl: '.js-homeProjectCarouselSwiper2Prev_tab3',
    },
    pagination: {
      clickable: true,
      el: '.js-homeProjectCarouselSwiper2Pagination_tab3',
    },
    slidesPerView: 1.5,
  };

  let homeProjectCarouselSwiper2_tab3 = new Swiper('#js-homeProjectCarouselSwiper2_tab3', homeProjectCarouselSwiper2_tab3Options);

  const homeProjectCarouselSwiper2_tab4Options = {
   autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 12,
      },
    },
    loop: true,
    spaceBetween: 8,
    navigation: {
      nextEl: '.js-homeProjectCarouselSwiper2Next_tab4',
      prevEl: '.js-homeProjectCarouselSwiper2Prev_tab4',
    },
    pagination: {
      clickable: true,
      el: '.js-homeProjectCarouselSwiper2Pagination_tab4',
    },
    slidesPerView: 1.5,
  };

  let homeProjectCarouselSwiper2_tab4 = new Swiper('#js-homeProjectCarouselSwiper2_tab4', homeProjectCarouselSwiper2_tab4Options);

  new Swiper('#js-homeVoiceCarouselSwiper', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      375: {
        slidesPerView: 'auto',
      },
    },
    centeredSlides: true,
    loop: true,
    spaceBetween: 16,
    navigation: {
      nextEl: '.js-homeVoiceCarouselSwiperNext',
      prevEl: '.js-homeVoiceCarouselSwiperPrev',
    },
    pagination: {
      clickable: true,
      el: '.js-homeVoiceCarouselSwiperPagination',
    },
    slidesPerView: 1.2,
  });

  //オススメ案件　タブ
  $(function () {
    const homeProjectCarouselSwipers = [homeProjectCarouselSwiper2_tab1, homeProjectCarouselSwiper2_tab2, homeProjectCarouselSwiper2_tab3, homeProjectCarouselSwiper2_tab4];
    $('.p-homeProject__content .p-homeProject__data').hide();
    $('.p-homeProject__content .p-homeProject__data.show').show();
    $('.p-homeProject__tabList li').click(function () {
      var index = $('.p-homeProject__tabList > li').index(this);
      $('.p-homeProject__tabList > li a').removeClass('-active');
      $(this).children('a').addClass('-active');
      $('.p-homeProject__content > .p-homeProject__data')
        .removeClass('show')
        .hide()
        .eq(index)
        .addClass('show')
        .show();
      $('.jsHomeProjectButton').removeClass('-active');
      $('.jsHomeProjectButton').eq(index).addClass('-active');
      homeProjectCarouselSwipers.forEach(function(swiper) {
        swiper.destroy();
      });
      if (index == 0) {
        homeProjectCarouselSwiper2_tab1 = new Swiper('#js-homeProjectCarouselSwiper2_tab1', homeProjectCarouselSwiper2_tab1Options);
      } else if(index == 1) {
        homeProjectCarouselSwiper2_tab2 = new Swiper('#js-homeProjectCarouselSwiper2_tab2', homeProjectCarouselSwiper2_tab2Options);
      } else if(index == 2) {
        homeProjectCarouselSwiper2_tab3 = new Swiper('#js-homeProjectCarouselSwiper2_tab3', homeProjectCarouselSwiper2_tab3Options);
      } else {
        homeProjectCarouselSwiper2_tab4 = new Swiper('#js-homeProjectCarouselSwiper2_tab4', homeProjectCarouselSwiper2_tab4Options);
      }
    });
  });

  $('#js-otherServiceTrigger').on('click', (event) => {
    let deviceWidth = $(window).width();
    if (deviceWidth < 992) {
      $(event.currentTarget).toggleClass('-active');
      $('#js-otherServiceTarget').slideToggle();
    }
    return false;
  });

  new Swiper('#js-recommendCarouselSwiper', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 8,
      },
    },
    loop: true,
    spaceBetween: 5,
    navigation: {
      nextEl: '.js-recommendCarouselSwiperNext',
      prevEl: '.js-recommendCarouselSwiperPrev',
    },
    pagination: {
      clickable: true,
      el: '.js-recommendCarouselSwiperPagination',
    },
    slidesPerView: 1.5,
  });

  $('#js-sp-nav-close').on('click', (event) => {
    $('#js-sp-nav').slideUp();
    return false;
  });
  $('.js-sp-nav-trigger').on('click', (event) => {
    $(event.currentTarget).toggleClass('-active');
    $(event.currentTarget).next('.js-sp-nav-target').slideToggle();
    return false;
  });

  $('.js-topic-outline-trigger').on('click', (event) => {
    $(event.currentTarget).toggleClass('-active');
    if ($(event.currentTarget).hasClass('-active')) {
      $(event.currentTarget).text('目次を開く');
    } else {
      $(event.currentTarget).text('目次を閉じる');
    }
    $('.js-topic-outline-target').slideToggle();
    return false;
  });

  //スキルワードから案件を探す　ドラッグ操作
  $(function () {
    $.prototype.draggable = function () {
      var t;
      $(this).each(function (i, e) {
        $(e).mousedown(function (e2) {
          e2.preventDefault();
          t = $(e);
          $(e).data({
            down: true,
            x: e2.clientX,
            y: e2.clientY,
            left: $(e).scrollLeft(),
            top: $(e).scrollTop(),
          });
        });
      });
      $(document)
        .mousemove(function (e) {
          if ($(t).data('down')) {
            e.preventDefault();
            $(t).scrollLeft($(t).data('x') + $(t).data('left') - e.clientX);
            $(t).scrollTop($(t).data('y') + $(t).data('top') - e.clientY);
          }
        })
        .mouseup(function (e) {
          $(t).data('down', false);
        });
    };
    $('.p-homeSerach__choice').draggable();
  });

  // アンカーリンクをスムーススクロールにする
  $('a[href^="#"]').on('click', function (event) {
    // aタグのデフォルト動作を停止
    event.preventDefault();

    // クリックされたアンカーリンクのhref属性を取得
    var target = $(this).attr('href');

    // href属性が空か、#の場合は、ページトップにスムーススクロール
    if (target === '#' || target === '') {
      $('html, body').animate({ scrollTop: 0 }, 500);
    }
    // href属性に#が含まれる場合は、ターゲットにスムーススクロール
    else if (target.indexOf('#') === 0) {
      // idから#を除いた文字列を取得
      var id = target.substring(1);
      // ターゲットの存在を確認
      if ($('#' + id).length) {
        // ウィンドウ幅が768px以上の場合は-200、未満の場合は-100にスクロール位置を調整
        var scrollPosition =
          $(window).width() >= 768
            ? $('#' + id).offset().top - 200
            : $('#' + id).offset().top - 150;
        // ターゲットにスムーススクロール
        $('html, body').animate({ scrollTop: scrollPosition }, 500);
      }
    }

    $('#js-sp-nav.--fix31').slideUp();
  });

  // 案件一覧ページpegar制御
  if ($('.p-pager').length && window.matchMedia('(max-width: 767px)').matches) {
    var liCount = $('.p-pager li').length;
    if (liCount >= 7) {
      var centerIndex = Math.floor(liCount / 2);
      var hereIndex = $('.p-pager li a.-here').parent().index();
      if (hereIndex >= 0) {
        centerIndex = hereIndex;
      }
      var visibleCount = liCount - 7;

      $('.p-pager li').addClass('u-hidden');

      for (var i = centerIndex - 1; i <= centerIndex + 1; i++) {
        if (i >= 0 && i < liCount) {
          $('.p-pager li').eq(i).removeClass('u-hidden');
        }
      }
      $('.p-pager li').slice(0, 2).removeClass('u-hidden');
      $('.p-pager li').slice(-2).removeClass('u-hidden');
    }
  }

  // 正社員からフリーランス転向を検討される方へのMidworksのサポート体制セクションのカードの高さを合わせる
  const selectItems = document.querySelectorAll('.c-selectItem__text');
  if (selectItems.length) {
    let maxHeight = 0;
    selectItems.forEach((item) => {
      const height = item.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    selectItems.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  }
  const selectItems02 = document.querySelectorAll('.c-selectItem__subHeading');
  if (selectItems02.length) {
    let maxHeight = 0;
    selectItems02.forEach((item) => {
      const height = item.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    selectItems02.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  }

  // 福利厚生パッケージプランセクションのアコーディオン要素の高さを揃える（PCタブレットのみ）
  if (document.querySelector('.c-accordionItem__header') !== null) {
    // スマホ以外の場合の処理
    if (window.matchMedia('(min-width: 768px)').matches) {
      var headers = document.querySelectorAll('.c-accordionItem__header');
      var maxHeight = 0;
      for (var i = 0; i < headers.length; i++) {
        maxHeight = Math.max(maxHeight, headers[i].clientHeight);
      }
      for (var j = 0; j < headers.length; j++) {
        headers[j].style.height = maxHeight + 'px';
      }
    }
  }
  // 福利厚生パッケージプランセクションのアコーディオン要素の高さを揃える（PCタブレットのみ）
  if (document.querySelector('.c-columnItem2__heading') !== null) {
    var headers = document.querySelectorAll('.c-columnItem2__heading');
    var maxHeight = 0;
    for (var i = 0; i < headers.length; i++) {
      maxHeight = Math.max(maxHeight, headers[i].clientHeight);
    }
    for (var j = 0; j < headers.length; j++) {
      headers[j].style.height = maxHeight + 'px';
    }
  }

  // 法人ページ「フリーランスの導入に不安がある担当者様へ」セクションのカード要素のタイトル高さを揃える（PCタブレットのみ）
  if (
    document.querySelector('.c-featureItem2__heading.--fix18.--fixjs01') !==
    null
  ) {
    // スマホ以外の場合の処理
    if (window.matchMedia('(min-width: 768px)').matches) {
      var headers = document.querySelectorAll(
        '.c-featureItem2__heading.--fix18.--fixjs01'
      );
      var maxHeight = 0;
      for (var i = 0; i < headers.length; i++) {
        maxHeight = Math.max(maxHeight, headers[i].clientHeight);
      }
      for (var j = 0; j < headers.length; j++) {
        headers[j].style.height = maxHeight + 'px';
      }
    }
  }
  if (
    document.querySelector('.c-featureItem2__heading.--fix18.--fixjs02') !==
    null
  ) {
    // スマホ以外の場合の処理
    if (window.matchMedia('(min-width: 768px)').matches) {
      var headers = document.querySelectorAll(
        '.c-featureItem2__heading.--fix18.--fixjs02'
      );
      var maxHeight = 0;
      for (var i = 0; i < headers.length; i++) {
        maxHeight = Math.max(maxHeight, headers[i].clientHeight);
      }
      for (var j = 0; j < headers.length; j++) {
        headers[j].style.height = maxHeight + 'px';
      }
    }
  }

  // 会員登録ページ吹き出しの制御

  $('.p-registrationFlow__speech__btn.--no01').hover(
    function () {
      const $speechText = $('.p-registrationFlow__speech__text.--no01');
      const textWidth = $speechText.width();
      $speechText
        .css({
          position: 'absolute',
          left:
            $(this).position().left - textWidth / 2 + $(this).width() / 2 - 17,
          top: $(this).position().top - $speechText.height() - 17,
        })
        .show();
    },
    function () {
      $('.p-registrationFlow__speech__text.--no01').hide();
    }
  );
  $('.p-registrationFlow__speech__btn.--no02').hover(
    function () {
      const $speechText = $('.p-registrationFlow__speech__text.--no02');
      const textWidth = $speechText.width();
      $speechText
        .css({
          position: 'absolute',
          left:
            $(this).position().left - textWidth / 2 + $(this).width() / 2 - 17,
          top: $(this).position().top - $speechText.height() - 17,
        })
        .show();
    },
    function () {
      $('.p-registrationFlow__speech__text.--no02').hide();
    }
  );
  $('.p-registrationFlow__speech__btn.--no03').hover(
    function () {
      const $speechText = $('.p-registrationFlow__speech__text.--no03');
      const textWidth = $speechText.width();
      $speechText
        .css({
          position: 'absolute',
          left:
            $(this).position().left - textWidth / 2 + $(this).width() / 2 - 18,
          top: $(this).position().top - $speechText.height() - 17,
        })
        .show();
    },
    function () {
      $('.p-registrationFlow__speech__text.--no03').hide();
    }
  );
  $('.p-registrationFlow__speech__btn.--no04').hover(
    function () {
      const $speechText = $('.p-registrationFlow__speech__text.--no04');
      const textWidth = $speechText.width();
      $speechText
        .css({
          position: 'absolute',
          left:
            $(this).position().left - textWidth / 2 + $(this).width() / 2 - 17,
          top: $(this).position().top - $speechText.height() - 17,
        })
        .show();
    },
    function () {
      $('.p-registrationFlow__speech__text.--no04').hide();
    }
  );

  // 法人ページ吹き出しの制御

  $('.p-registrationFlow__speech__btn.--no05').hover(
    function () {
      const $speechText = $('.p-businessCompany__number__text');
      const textWidth = $speechText.width();
      $speechText.show();
    },
    function () {
      $('.p-businessCompany__number__text').hide();
    }
  );

$(document).ready(function() {
  let hash = window.location.hash;
  if(hash && hash != "#flow") {
    let target = $(hash);
    if (target.length) {
      if($(window).width() >= 768) {
        var marginTop = 200;
      } else {
        var marginTop = 150;
      }
      var offsetTop = target.offset().top - marginTop;
      $('html, body').animate({scrollTop: offsetTop}, 0);
    }
  }
});
